.login-div {
    background: #000000ad;
    min-height: 100vh;
    display: flex;
}
.main-background {
    max-width: 380px;
    overflow: auto;
    margin: auto;
}
.email {
    display: flex;
    align-items: center;
    border: 1px solid #525252;
    border-radius: 5px;
    padding: 0px 10px;
    margin: 20px 0;
    background: #00000059;
}
.email span {
    border-right: 1px solid #525252;
    padding: 10px 10px 10px 0;
}
.email svg {
    width: 100%;
    display: block;
}
.email-input {
    border: none;
    background: none;
    outline: none;
    font-size: 15px;
    padding: 0px;
    margin: 0 10px;
    color: #fff;
    width: 100%;
}
.email-input::placeholder {
    color: #a1a1a1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-text-fill-color: #fff;
    -webkit-background-clip: text;
    transition: background-color 60000s ease-in-out 0s, color 60000s ease-in-out 0s;
}

.password {
    display: flex;
    align-items: center;
    border: 1px solid #525252;
    border-radius: 5px;
    padding: 0px 10px;
    margin: 20px 0;
    background: #00000059;
}
.password span {
    border-right: 1px solid #525252;
    padding: 10px 10px 10px 0;
}
.password svg {
    width: 100%;
    display: block;
}
.password-input {
    border: none;
    background: none;
    outline: none;
    font-size: 15px;
    padding: 0px;
    margin: 0 10px;
    color: #fff;
    width: 100%;
}
.password-input::placeholder {
    color: #a1a1a1;
}
.video-login {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.loginvideo {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 100%;
    object-fit: cover;
}
.video-login::before {
    content: "";
    position: absolute;
    background: linear-gradient( 0deg, rgb(1 1 1), rgb(1 1 1 / 98%) 13%, rgb(1 1 1 / 87%) 22%, rgb(1 1 1 / 75%) 41%, rgba(0, 0, 0, 0.47) );
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.login-btn {
    border: 1px solid;
    padding: 11px;
    border-radius: 6px;
    width: auto;
    background: transparent;
    border-color: #cca554;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin: auto;
    cursor: pointer;
}
.login-btn:hover {
    background: #eed996;
    border-color: #eed996;
    color: #000;
}
.buttonlogin {
    margin: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
.password-reset,
.signup-btn {
    margin: 5px 0;
    display: flex;
    justify-content: center;
}
.signup-btn button {
    padding: 9px;
    border: 1px solid;
    background-color: #eed996;
    border-color: #eed996;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    color: #000;
}
.signup-btn button:hover {
    background: #cca554;
    border-color: #cca554;
    color: #000;
}
.password-reset button {
    padding: 9px;
    border: 1px solid;
    background-color: #eed996;
    border-color: #eed996;
    font-weight: 500;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
}
.password-reset button:hover {
    background: #cca554;
    border-color: #cca554;
}
.reset-pass {
    text-align: right;
}
.reset-pass a {
    color: #fff;
}
.reset-pass a:hover {
    text-decoration: underline !important;
    color: #fff;
}
.signup-text {
    text-align: center;
    color: #fff;
    padding-top: 15px;
}
.signup-text a {
    color: #eed996;
    font-size: 18px;
}
.signup-text a:hover {
    color: #eed996;
    text-decoration: underline !important;
}
