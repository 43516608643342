.welcome-term {
    /* min-height: 470px;
    height: 55vh; */
    position: absolute;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.welcome-term::before {
    content: "";
    position: absolute;
    background: linear-gradient(
        0deg,
        rgb(1 1 1),
        rgb(1 1 1 / 72%) 13%,
        rgb(1 1 1 / 62%) 22%,
        rgb(1 1 1 / 54%) 41%,
        rgb(0 0 0 / 29%)
    );
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.headervideo {
    width: 100%;
    z-index: -1;
    height: 100%;
    object-fit: cover;
}
.header-wrapper {
    position: relative;
    height: 100vh;
}
.header-wrapper .arrow-down {
    position: absolute;
    bottom: 130px;
    right: 10px;
    cursor: pointer;

    -webkit-animation: scrolling 2s ease infinite;
    -moz-animation: scrolling 23s ease infinite;
    -o-animation: scrolling 2s  ease infinite;
    animation: scrolling 2s ease infinite;
}
.header-wrapper .arrow-down:hover {
    -webkit-animation: none;
    -moz-animation: none;
    -o-animation: none;
    animation: none;
}
.header-wrapper .header-text{
    color: #fff;
    /* font-family: "Perpetua Titling"; */
    font-size: 55px;
    position: absolute;
    bottom: 90px;
    top: auto;
    left: 10px;
    height: 120px;
    margin: 0;
}
.header-wrapper .header-text .header-sub-title {
    width: 80%;
}
.grid figure:before {
    content: "";
    position: absolute;
    background: #00000047;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}
*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.content {
    margin: 0 auto;
    max-width: 1000px;
    display: flex;
}

.content > h2 {
    clear: both;
    margin: 0;
    padding: 4em 1% 0;
    color: #484b54;
    font-weight: 800;
    font-size: 1.5em;
}

.content > h2:first-child {
    padding-top: 0em;
}

/* Header */
.codrops-header {
    margin: 0 auto;
    padding: 4em 1em;
    text-align: center;
}

.codrops-header h1 {
    margin: 0;
    font-weight: 800;
    font-size: 4em;
    line-height: 1.3;
}

.codrops-header h1 span {
    display: block;
    padding: 0 0 0.6em 0.1em;
    color: #74777b;
    font-weight: 300;
    font-size: 45%;
}

.grid {
    position: relative;
    margin: 0 auto;
    padding: 1em 0 4em;
    max-width: 1000px;
    list-style: none;
    text-align: center;
}

/* Common style */
.grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
    min-width: 319px;
    max-width: 550px;
    max-height: 300px;
    text-align: center;
    cursor: pointer;
}

.grid figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
}

.grid figure figcaption {
    padding: 2em;
    color: #fff;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
    pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.grid figure figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.grid figure h2 span {
    font-weight: 800;
}

.grid figure h2,
.grid figure p {
    margin: 0;
}

.grid figure p {
    letter-spacing: 1px;
    font-size: 68.5%;
}

figure.effect-layla img {
    height: 390px;
}

figure.effect-layla figcaption {
    padding: 3em;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

figure.effect-layla figcaption::before,
figure.effect-layla figcaption::after {
    position: absolute;
    content: "";
    opacity: 0;
}

figure.effect-layla figcaption::before {
    top: 50px;
    right: 30px;
    bottom: 50px;
    left: 30px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

figure.effect-layla figcaption::after {
    top: 30px;
    right: 50px;
    bottom: 30px;
    left: 50px;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
}

figure.effect-layla h2 {
    padding-top: 10%;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
}

figure.effect-layla p {
    display: none;
    padding: 0.5em 2em;
    text-transform: none;
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
}

figure.effect-layla img,
figure.effect-layla figcaption::before,
figure.effect-layla figcaption::after,
figure.effect-layla p {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

figure.effect-layla:hover img {
    transform: scale(1.2);
}


figure.effect-layla:hover figcaption::after,
figure.effect-layla:hover h2,
figure.effect-layla:hover p,
figure.effect-layla:hover img {
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}
@media screen and (max-width: 767px) {
    .grid {
        padding: 1em 0 0em;
    }
    .grid figure {
        min-width: 290px;
        max-width: 100vw;
        max-height: 100vw;
    }
}

@keyframes scrolling {
    0% {
      transform: translate(0);
    }
   
    50% {
      transform: translate(0,40px);}
  
   
    100% {
       transform: translate(0);
    }
  }
