.header-div {
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;
}
.payment-completed .header-maindiv {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.payment-completed .header-payment .icon {
    margin-bottom: 10px;
}

.payment-completed .header-payment .title {
    color: #eed996;
}

.payment-completed .header-payment{
    margin-bottom: 1em;
    text-align: center;
}
.confirm-detail {
    text-align: center;
    color: #ababab;
}

.confirm-detail .btn{
    color: #eed996;
}

.confirm-detail .btn:hover{
    color: #fbb72c;
}
