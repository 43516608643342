.modal {
  background-color: #000000ad;
}

.modal-content {
  background-color: #000000ad !important;
  border: solid 1px #eed996 !important;
}

.modal-content .modal-primary-btn {
  background: #eed996 !important;
  color: #000 !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  padding: 7px 25px !important;
  font-size: 16px !important;
  border: none !important;
  text-transform: capitalize;
}

.modal-content .modal-secondary-btn {
  background: transparent !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  padding: 7px 25px !important;
  font-size: 16px !important;
  border: solid 1px #eed996 !important;
  text-transform: capitalize;
}

.modal-primary-btn:hover {
  background: #fbb72c !important;
}

.modal-secondary-btn:hover {
  background: #eed996 !important;
  border-color: #eed996 !important;
  color: #000 !important;
}

.modal-content .title {
  color: #eed996 !important;
}