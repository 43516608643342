.slider-product {
    height: 80vh;
    position: relative;
    overflow: hidden;
}
.slider-product .carousel-inner,
.slider-product .carousel-inner .carousel-item, 
.slider-product .carousel-inner .carousel-item img {
    height: 100%;
    object-fit: cover;
}
.carousel {
    height: 100%;
}
.video-term::before {
    content: "";
    position: absolute;
    background: linear-gradient(
        0deg,
        rgb(1 1 1),
        rgb(1 1 1 / 98%) 13%,
        rgb(1 1 1 / 87%) 22%,
        rgb(1 1 1 / 75%) 41%,
        rgba(0, 0, 0, 0.47)
    );
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.headervideo {
    width: 100%;
    z-index: -1;
    height: 100%;
    object-fit: cover;
}
.price {
    margin: 0;
    color: #fbb72c;
    font-size: 18px;
    font-weight: 600;
}
.people {
    color: #eed996;
    font-size: 15px;
}
.map {
    display: none;
}
.product-title {
    color: #eed996;
    font-size: 25px;
    margin: 0;
    margin-bottom: 20px;
}
.product-description {
    line-height: 1.7;
    padding-left: 18px;
}
.product-links {
    cursor: pointer;
    width: 50%;
}
.rating-star {
    width: 130px;
    display: none;
}
.rating-star img {
    width: 100%;
    display: block;
}
.buynow-maindiv {
    display: flex;
    justify-content: flex-end;
}
.buy-now {
    background: #eed996;
    color: #000;
    font-weight: 600;
    border-radius: 8px;
    padding: 7px 25px;
    font-size: 16px;
}
.buy-now:hover {
    background: #fbb72c;
}
.arrows {
    display: flex;
    justify-content: space-between;
}
.arrows .arrow{
    cursor: pointer;
    color: #eed996;
    padding: 10px;
}
.arrow.back {
    
}
.product-info {
    z-index: 1;
    position: absolute;
    width: 100%;
    padding-bottom: 15px;
    bottom: 0px;
    background: linear-gradient(0deg, #000000cf 50%, transparent 100%);
}
.favorite-wrapper {
    width: 24px;
}
.product-nav {
    margin-top: 68px;
    border-top: solid #eed996 1px;
}
@media screen and (max-width: 767px) {
    .sm-detail {
        position: relative;
    }
    .title-last {
        order: 3;
    }
    .price-sec {
        order: 2;
    }
    .button-top {
        order: 1;
        position: absolute;
        top: -20px;
    }
    .slider-product {
        height: 100vw;
    }
    .slider-product .carousel-inner,
    .slider-product .carousel-inner .carousel-item, 
    .slider-product .carousel-inner .carousel-item img {
        height: 100vw;
    }
    .carousel {
        height: 100vw;
    }
}
