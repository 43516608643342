@font-face {
  font-family: "Perpetua Titling";
  src: url(./assets/font/perpetua-titling-mt-light.ttf);
}
@font-face {
  font-family: "Geo Sans";
  src: url(./assets/font/GeosansLight.ttf);
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
::selection {
  background: white;
  color: #010101;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #010101 !important;
  color: #a1a1a1 !important;
  font-family: "Geo Sans" !important;
}

p {
  font-size: 17px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}
.active.fade-bottom {
  animation: fade-bottom 0.7s ease-in;
}
.active.fade-left {
  animation: fade-left 0.7s ease-in;
}
.active.fade-right {
  animation: fade-right 0.7s ease-in;
}
.active.fade-in {
  animation: fade-in 0.7s ease-in;
}
@keyframes fade-in {
  0% {
    /* transform: translateY(50px); */
    opacity: 0;
  }
  100% {
    /* transform: translateY(0); */
    opacity: 1;
  }
}
@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
