a {
  text-decoration: none !important;
}
.checkout-row {
  align-items: center;
  border-bottom: 1px solid;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.product-count input {
  width: 100%;
  padding: 10px;
  max-width: 50px;
  font-size: 16px;
  text-align: center;
  background: transparent;
  border: none;
  border-radius: 5px;
  color: #fff;
}
.product-count a {
  text-decoration: none;
  color: #fff;
}
.product-count a:hover {
  text-decoration: none;
  color: #eed996;
}
.checkout-image img {
  width: 100%;
  display: block;
}
.checkout-title {
  color: #eed996;
}
.checkout-people {
  color: #fbb72c;
}
.price-list {
  color: #eed996;
}
.price-total {
  color: #fbb72c;
}
.main-total {
  text-align: right;
}
.price-totallast {
  color: #fbb72c;
  font-size: 20px;
  font-weight: 600;
}
.credit-message {
  margin-top: 20px !important;
}

.continue-shop {
  max-height: 38px;
  margin-top: 280px;
  background: #eed996 !important;
  color: #000 !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  padding: 7px 25px !important;
  font-size: 16px !important;
  border: none !important;
}

.remove-btn {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: white;
  margin-top: 5px;
}

@media screen and (max-width: 767px) {
  .checkout-title {
    font-size: 18px;
  }
  .price-list, .price-total {
    font-size: 15px;
  }
  .top-row {
    padding-top: 20px;
  }
}
