.login-div {
    background: #000000ad;
    min-height: 100vh;
    display: flex;
}
.main-background {
    max-width: 380px;
    overflow: auto;
    margin: auto;
}
.email {
    display: flex;
    align-items: center;
    border: 1px solid #eed996;
    border-radius: 5px;
    padding: 0px 10px;
    margin: 20px 0;
    background: #00000059;
}
.email span {
    border-right: 1px solid #eed996;
    padding: 10px 10px 10px 0;
}
.email svg {
    width: 100%;
    display: block;
}
.email-input {
    border: none;
    background: none;
    outline: none;
    font-size: 15px;
    padding: 0px;
    margin: 0 10px;
    color: #fff;
}
.email-input::placeholder {
    color: #a1a1a1;
}

.password {
    display: flex;
    align-items: center;
    border: 1px solid #eed996;
    border-radius: 5px;
    padding: 0px 10px;
    margin: 20px 0;
    background: #00000059;
}
.password span {
    border-right: 1px solid #eed996;
    padding: 10px 10px 10px 0;
}
.password svg {
    width: 100%;
    display: block;
}
.password-input {
    border: none;
    background: none;
    outline: none;
    font-size: 15px;
    padding: 0px;
    margin: 0 10px;
    color: #fff;
}
.password-input::placeholder {
    color: #a1a1a1;
}

.login-btn {
    border: 1px solid;
    padding: 11px;
    border-radius: 6px;
    width: auto;
    background: transparent;
    border-color: #cca554;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin: auto;
    cursor: pointer;
}
.login-btn:hover {
    background: #eed996;
    border-color: #eed996;
    color: #000;
}
.buttonlogin {
    margin: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
.password-reset,
.signup-btn {
    margin: 5px 0;
    display: flex;
    justify-content: center;
}
.signup-btn button {
    padding: 9px;
    border: 1px solid;
    background-color: #eed996;
    border-color: #eed996;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    color: #000;
}
.signup-btn button:hover {
    background: #cca554;
    border-color: #cca554;
    color: #000;
}
.password-reset button {
    padding: 9px;
    border: 1px solid;
    background-color: #eed996;
    border-color: #eed996;
    font-weight: 500;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
}
.password-reset button:hover {
    background: #cca554;
    border-color: #cca554;
}
@media screen and (max-width: 767px) {
}
