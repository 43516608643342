.bottom-agreements {
  margin: 20px 0 !important;
  color: #ababab;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  max-width: fit-content !important;
}
.bottom-agreements a {
  text-decoration: underline !important;
}
