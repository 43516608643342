.video-term {
  /* min-height: 470px;
    height: 55vh; */
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.video-term::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    0deg,
    rgb(1 1 1),
    rgb(1 1 1 / 98%) 13%,
    rgb(1 1 1 / 87%) 22%,
    rgb(1 1 1 / 75%) 41%,
    rgba(0, 0, 0, 0.47)
  );
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.headervideo {
  width: 100%;
  z-index: -1;
  height: 100%;
  object-fit: cover;
}
.product-card {
  width: 480px;
  position: relative;
  margin: 50px auto;
  margin-bottom: 0;
  background: #fafafa;
  border-radius: 10px;
}

.product-title.bottom-info {
  width: 480px;
  margin: 0 auto;
  margin: 20px auto;
  cursor: pointer;
}

.badge-per {
  position: absolute;
  right: 6px;
  top: 6px;
  font-size: 13px;
  font-weight: 700;
  background: #1d1d1d;
  color: #fff;
  padding: 4px 12px;
  border-radius: 5px;
}
a {
  text-decoration: none;
}
.product-tumb {
  height: 350px;
  background: #f0f0f0;
  cursor: pointer;
}

.product-tumb img {
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
}

.product-details {
  padding: 15px 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: #000000ad;
}

.product-catagory {
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ccc;
  margin-bottom: 8px;
}

.product-details h4 {
  font-weight: 500;
  display: block;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #eed996;
  text-decoration: none;
  transition: 0.3s;
}

.product-details h4:hover {
  color: #fbb72c;
}

.product-details p {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 15px;
  color: #999;
}

.product-bottom-details {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.product-price {
  font-size: 18px;
  color: #fbb72c;
  font-weight: 600;
}

.product-price .special-price {
  display: none;
}

.product-price small {
  font-size: 80%;
  font-weight: 400;
  text-decoration: line-through;
  display: inline-block;
  margin-right: 5px;
  color: #d9d9d9;
}

.product-links {
  text-align: right;
}

.product-links a {
  display: inline-block;
  margin-left: 5px;
  color: #e1e1e1;
  transition: 0.3s;
  font-size: 17px;
}

.product-links a:hover {
  color: #fbb72c;
}
.card-wrapper {
  margin-bottom: 20px;
}
.product-card .product-details h4 {
  display: none;
}

.product-tumb .carousel-inner, .product-tumb .carousel-inner .carousel-item, .product-tumb .carousel-inner .carousel-item img {
  height: 100%;
  object-fit: cover;
}

.product-tumb .carousel-indicators {
  margin-bottom: 3.5rem;
}


@media screen and (max-width: 767px) {
  .product-tumb {
    height: 100vw;
    width: 100vw;
  }
  .product-card {
    width: auto;
    margin: 40px calc(var(--bs-gutter-x) * (-.5)) 0;
  }
  .product-title.bottom-info {
    width: auto;
  }
  .product-price {
    min-width: 150px;
  }
}
