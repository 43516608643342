body {
    margin-top: 20px;
    background-color: #f2f6fc;
    color: #69707a;
}
.img-account-profile {
    height: 10rem;
}
.rounded-circle {
    border-radius: 50% !important;
}
.card {
    /*box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%);*/
    border: none !important;
    background: none !important;
}
.card .card-header {
    font-weight: 500;
}
.card-header:first-child {
    border-radius: 0.35rem 0.35rem 0 0;
}
.card-header {
    padding: 1rem 1.35rem;
    margin-bottom: 0;
    /*background-color: rgba(33, 40, 50, 0.03);*/
    border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}
.form-control,
.dataTable-input {
    display: block;
    width: 100%;
    padding: 0.875rem 1.125rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    color: #69707a;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c5ccd6;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.nav-borders .nav-link.active {
    color: #eed996;
    border-bottom-color: #eed996;
}
.nav-borders .nav-link {
    color: #69707a;
    border-bottom-width: 0.125rem;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    margin-left: 1rem;
    margin-right: 1rem;
}
.fa-2x {
    font-size: 2em;
}

.table-billing-history th,
.table-billing-history td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.375rem;
    padding-right: 1.375rem;
}
.table > :not(caption) > * > *,
.dataTable-table > :not(caption) > * > * {
    padding: 0.75rem 0.75rem;
    /*background-color: var(--bs-table-bg);*/
    border-bottom-width: 1px;
    /*box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);*/
}

.border-start-primary {
    border-left-color: #0061f2 !important;
}
.border-start-secondary {
    border-left-color: #6900c7 !important;
}
.border-start-success {
    border-left-color: #00ac69 !important;
}
.border-start-lg {
    border-left-width: 0.25rem !important;
}
.h-100 {
    height: 100% !important;
}
.table {
    color: #a1a1a1 !important;
}
.box-color {
    color: #eed996;
}
.box-color:hover {
    color: #cca554;
}
.buttony-gold {
    background-color: #eed996;
}
.buttony-gold:hover {
    background-color: #cca554;
}
.order-history {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid;
}
.order-history p {
    margin: 0;
    padding: 10px;
    /* min-width: 150px; */
}
.table-billing-history {
    padding-bottom: 20px;
}
.date-order {
    width: 110px;
}
.item-order {
    width: 320px;
}
.titleorder-add {
    display: none;
}
@media screen and (max-width: 767px) {
    .order-sm-off {
        display: none;
    }
    .titleorder-add {
        display: block;
    }
    .order-history {
        flex-wrap: wrap;
        position: relative;
    }
    .order-history p {
        width: 50%;
    }
    .date {
        order: 2;
    }
    .orderid {
        order: 4;
    }
    .item-title {
        order: 1;
        width: 100% !important;
    }
    .code {
        order: 5;
        text-align: right;
    }
    .total-order {
        order: 6;
    }
    .button-history {
        order: 3;
        text-align: right;
    }
    .badge {
        padding: 1em 1.5em !important;
        font-size: 0.85em !important;
    }
    /* .sm-button-order {
        position: absolute;
        top: 7px;
        width: auto !important;
        right: 10px;
    }
    .order-code {
        position: absolute;
        top: 3.2em;
        width: auto !important;
        right: 18px;
    } */
}
