.printer-top {
    z-index: 1;
    border: 6px solid #666666;
    height: 6px;
    border-bottom: 0;
    border-radius: 6px 6px 0 0;
    background: #333333;
}

.printer-bottom {
    z-index: 0;
    border: 6px solid #666666;
    height: 6px;
    border-top: 0;
    border-radius: 0 0 6px 6px;
    background: #333333;
}

.paper-container {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.paper {
    background: #d5d5d5;
    height: 100%;
    /* position: absolute; */
    z-index: 2;
    margin: 0 12px;
    margin-top: -12px;
    animation: print 1800ms cubic-bezier(0.68, -0.55, 0.265, 0.9);
    -moz-animation: print 1800ms cubic-bezier(0.68, -0.55, 0.265, 0.9);
}

.main-contents {
    margin: 0 12px;
    padding: 24px;
}

.jagged-edge {
    position: relative;
    height: 20px;
    width: 100%;
    margin-top: -1px;
}

.jagged-edge:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -9px;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(45deg, transparent 33.333%, #ffffff 33.333%, #ffffff 66.667%, transparent 66.667%),
        linear-gradient(-45deg, transparent 33.333%, #ffffff 33.333%, #ffffff 66.667%, transparent 66.667%);
    background-size: 16px 40px;
    background-position: 0 -20px;
}

.success-icon {
    text-align: center;
    font-size: 48px;
    background: #eed996;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    margin: 16px auto;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.success-title {
    font-size: 22px;
    text-align: center;
    color: #eed996;
    font-weight: bold;
    margin-bottom: 16px;
}

.success-description {
    font-size: 15px;
    line-height: 21px;
    color: #999;
    text-align: center;
    margin-bottom: 24px;
}

.order-details {
    text-align: center;
    color: #333;
    font-weight: bold;
}
.order-number-label {
    font-size: 18px;
    margin-bottom: 8px;
    color: #a1a1a1;
}
.order-number-label span {
    color: #eed996;
}
.order-number {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    line-height: 48px;
    font-size: 48px;
    padding: 8px 0;
    margin-bottom: 24px;
}

.order-footer {
    text-align: center;
    line-height: 18px;
    font-size: 18px;
    margin-bottom: 8px;
    font-weight: bold;
    color: #999;
}
.buy-now {
    background: #eed996;
    color: #000;
    font-weight: 600;
    border-radius: 8px;
    padding: 7px 25px;
    font-size: 16px;
    border: none;
}
.info-div {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}
@keyframes print {
    0% {
        transform: translateY(-90%);
    }
    100% {
        transform: translateY(0%);
    }
}
@-webkit-keyframes print {
    0% {
        -webkit-transform: translateY(-90%);
    }
    100% {
        -webkit-transform: translateY(0%);
    }
}

@-moz-keyframes print {
    0% {
        -moz-transform: translateY(-90%);
    }
    100% {
        -moz-transform: translateY(0%);
    }
}

@-ms-keyframes print {
    0% {
        -ms-transform: translateY(-90%);
    }
    100% {
        -ms-transform: translateY(0%);
    }
}
