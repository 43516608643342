.order-history {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
}
.order-history p {
  margin: 0;
  padding: 10px;
}
.table-billing-history {
  padding-bottom: 20px;
}
.date-order {
  width: 110px;
}
.item-order {
  width: 320px;
}
.titleorder-add {
  display: none;
}
@media screen and (max-width: 767px) {
  .order-sm-off {
    display: none;
  }
  .titleorder-add {
    display: block;
  }
  .order-history {
    flex-wrap: wrap;
    position: relative;
  }
  .order-history p {
    width: 50%;
  }
  .date {
    order: 2;
  }
  .orderid {
    order: 4;
  }
  .item-title {
    order: 1;
    width: 100% !important;
  }
  .code {
    order: 5;
    text-align: right;
  }
  .total-order {
    order: 6;
  }
  .button-history {
    order: 3;
    text-align: right;
  }
  .badge {
    padding: 1em 1.5em !important;
    font-size: 0.85em !important;
  }
}
