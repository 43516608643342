.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both;
}
input:focus,
textarea:focus,
keygen:focus,
select:focus {
    outline: none;
}
.header-wrapper.no-video {
    height: auto
}
.header-wrapper.no-video .header-text{
    position: relative;
    bottom: auto;
    margin-top: 40px;
}
.container {
    /* padding: 0 50px 70px; */
}
.textcenter {
    text-align: center;
}

.section2 {
    margin: 25px auto;
}
.section2 .col2 {
    width: 47.71%;
}
.section2 .col2.first {
    float: left;
}
.section2 .col2.last {
    float: right;
}
.section2 .col2.column2 {
    padding-right: 30px;
}
.section2 span.collig {
    color: #a2a2a2;
    margin-right: 10px;
    display: inline-block;
    font-size: 18px;
}
.section2 .sec2addr {
    display: block;
    line-height: 26px;
}
.section2 .sec2addr p:first-child {
    /* margin-bottom: 10px; */
}
.section2 .sec2contactform input[type="text"],
.section2 .sec2contactform input[type="email"],
.section2 .sec2contactform textarea {
    padding: 18px;
    border: 0;
    background: none;
    margin: 7px 0;
    border-bottom: 1px solid #a1a1a1;
    color: #fff;
    font-size: 19px;
}
.section2 .sec2contactform textarea {
    width: 100%;
    display: block;
    color: #fff;
    resize: none;
}
.section2 .sec2contactform input[type="submit"] {
    padding: 15px 40px;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    margin: 7px 0;
    cursor: pointer;
    border: 1px solid;
    border-radius: 5px;
    background: none;
}
.section2 .sec2contactform h3 {
    font-weight: normal;
    margin: 20px 0;
    margin-top: 30px;
    border-bottom: 1px solid #a1a1a1;
    padding-bottom: 30px;
    color: #eed996;
}
.whatsapp-icon {
    padding-left: 0;
    justify-content: flex-start;
}
.sec2innercont {
    margin-top: 80px;
}

/* @media querries */

@media only screen and (max-width: 1266px) {
    .section2 {
        width: 100%;
    }
}
@media only screen and (max-width: 960px) {
    .section2 .col2 {
        width: 100%;
        display: block;
    }
    .section2 .col2.first {
        margin-bottom: 10px;
    }
    .section2 .col2.column2 {
        padding: 0;
    }
    .sec2map {
        height: 250px !important;
    }
}
@media only screen and (max-width: 768px) {
    .section2 .sec2addr {
        font-size: 14px;
    }
    .section2 .sec2contactform h3 {
        font-size: 16px;
    }
    .section2 .sec2contactform input[type="text"],
    .section2 .sec2contactform input[type="email"],
    .section2 .sec2contactform textarea {
        padding: 10px;
        margin: 3px 0;
    }
    .section2 .sec2contactform input[type="submit"] {
        padding: 10px 30px;
        font-size: 14px;
    }
    iframe {
        width: 100%;
    }
    .section2 .col2.last {
        margin-top: 40px;
    }
}
@media only screen and (max-width: 420px) {
    .section1 h1 {
        font-size: 28px;
    }
}
